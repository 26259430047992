import { render, staticRenderFns } from "./species-form.vue?vue&type=template&id=0dbe0e0a&"
import script from "./species-form.vue?vue&type=script&lang=js&"
export * from "./species-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports